@import "src/styles/variables.scss";

.MessageList {
    overflow-y: auto;

    @media (min-width: 1200px) {
        height: 600px;
    }

    &.NoDataAvailable {
        height: initial;
    }

    .Message {
        font-family: Ubuntu, sans-serif;
        margin-bottom: 20px;

        .MessageDate {
            margin: 0 0 4px 20px;
            color: $primary;
            font-size: 14px;
            font-weight: 500;
        }

        .MessageBox {
            width: 100%;
            min-height: 176px;
            box-sizing: border-box;
            padding: 12px 30px 11px;
            border-radius: 25px 50px 0 25px;
            display: flex;
            flex-direction: column;
            background-color: $surface;
            letter-spacing: 0;
            line-height: 135%;

            .MessageBoxWineName {
                font-size: 18px;
                font-weight: 500;
                border: 0;
                margin-bottom: 10px;
                word-break: break-word;

                > span {
                    margin-right: 5px;
                    font-weight: 500;
                    font-size: 18px;
                    color: $primary;
                }
            }

            .MessageText {
                font-size: 16px;
                font-weight: 400;
                word-break: break-all;
            }
        }
    }
}
