@import "src/styles/variables.scss";

.ArrowDown {
	&.black {
		path {
			stroke: #2d2e2b;
		}
	}

	&.lighten {
		path {
			stroke: #f4f2f2;
		}
	}

	&.white {
		path {
			stroke: $secondary;
		}
	}

	&.red {
		path {
			stroke: $primary;
		}
	}
}
