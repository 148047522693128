@import "~bootstrap/scss/bootstrap.scss";
@import "~cnp-frontend-core";

#confirmation-popup-node {
	position: fixed;
	width: 1px !important;
	height: 1px !important;
	top: 0;
	left: 0;
	z-index: 10000;
}

// @FIXME: causes an error while building
@import "~bootstrap/dist/css/bootstrap-grid.css";

@import "variables.scss";
@import "font-face.scss";
@import "globals.scss";
