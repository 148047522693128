@mixin font-face(
	$name,
	$path,
	$weight: null,
	$style: null,
	$exts: eot woff2 woff ttf svg
) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_"),
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype",
	);

	@each $ext in $exts {
		$extmod: if(
			map-has-key($extmods, $ext),
			$ext + map-get($extmods, $ext),
			$ext
		);
		$format: if(
			map-has-key($formats, $ext),
			map-get($formats, $ext),
			$ext
		);
		$src: append(
			$src,
			url(quote($path + "." + $extmod)) format(quote($format)),
			comma
		);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@mixin font(
	$font,
	$font-weight,
	$size,
	$letter-spacing,
	$line-height
) {
	font-family: $font, sans-serif;
	font-size: $size;
	font-weight: $font-weight;
	letter-spacing: $letter-spacing;
	line-height: $line-height;
}
