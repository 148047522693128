@import "src/styles/variables.scss";

.ImageContainer {
	width: 200px;
	height: 140px;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
	position: relative;

	@media (max-width: 768px) {
		width: 110px;
		height: 90px;
		border-radius: 20px;
	}

	&.primary {
		background: $primary;
	}

	&.secondary {
		background: $secondary;
	}

	&.darken {
		background: $primaryDarken;
	}

	&.lighten {
		background: $white;
	}

	&.strictPadding {
	}

	&.disabled {
		cursor: not-allowed;
	}

	.Overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.4);
		border-radius: inherit;
	}
}

.loader {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;

	svg {
		height: 30px;
		width: 30px;
	}
}

.ImageButton {
	height: 78px;
	width: auto;

	@media (max-width: 768px) {
		height: 50px;
	}
}

.ImageButtonContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;

	&.loading {
		position: relative;

		.loader {
			visibility: visible;

			[class^="Loader_Loader"] {
				width: 60px;
				height: auto;
				margin-bottom: 0;

				@media (max-width: 768px) {
					width: 40px;
				}

				& > svg {
					width: inherit;
					height: auto;
				}
			}
		}

		.ImageButtonLabel {
			opacity: 0.3;
		}

		.ImageButton {
			opacity: 0.3;
		}

		.ImageContainer {
			background: rgba(0, 0, 0, 0.3);
		}
	}
}

.ImageButtonLabel {
	color: $backgroundLighten;

	@media (min-width: 768px) {
		font-size: 20px;
		width: 200px;
		text-align: center;
	}
}
