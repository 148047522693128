@import "src/styles/variables.scss";

.Footer {
	background: $primaryDarken;
	padding: 30px;
	display: flex;
	align-items: center;
	flex-direction: column;

	.logo {
	}
	.topSection {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 400px;
		width: 100%;

		@media (max-width: 450px) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: baseline;
			max-width: unset;
		}

		&.iconButtonsWrapper {
			@media (min-width: 450px) {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}

		&.topIndent {
			margin-top: 10px;
		}

		.socialsWrapper {
			display: flex;
			flex-direction: row;
			align-items: center;

			.topSectionLogo {
				margin: 20px 10px 0;
			}
		}

		li,
		div {
			// width: 100%;
			display: inline-flex;
			justify-content: space-around;

			&.topSectionLogo {
				background-color: $primaryDarken;
				img {
					height: 28px;
				}
			}

			&.topIndent {
				margin: 23px 0 0 0;
			}

			a {
				color: $backgroundLighten;
			}

			.siteLogo {
				font-size: 30px;
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}
	}

	div[class^="SelectLanguage_languageSelection"] {
		margin-left: 30px;
	}

	.siteNavigation {
		li {
			margin: 10px 0;
		}
	}

	.copyrights {
		padding: 28px 30px 0;
		max-width: 360px;
		width: 100%;

		p {
			text-align: center;
			color: $backgroundLighten;
		}
	}

	@media (min-width: 1200px) {
		.copyrights {
			max-width: 100%;
		}
	}
}
