@import "src/styles/variables.scss";
@import "src/styles/mixins/font-face.scss";

:global {
    .select__control {
        position: relative;
		cursor: pointer;
		min-height: 51px !important;
		box-sizing: border-box;
		display: inline-block;
		width: 100%;
		font-size: 16px !important;
		border: 0 !important;
		margin: 0 !important;
		border-radius: 20px !important;
		background: $surface !important;
		padding: 15px !important;
		@include font(Ubuntu !important, 500 !important, 18px !important, 0 !important, 135% !important);
		line-height: 100% !important;
    }

    .select__multi-value {
        background: #b3788f !important;
        color: white !important;
    }

    .select__multi-value__label {
        color: white !important;
        @include font(Ubuntu !important, 500 !important, 18px !important, 0 !important, 135% !important);
    }

    .select__control {
        outline: none !important;
    }
}

.SelectInput {
	padding-bottom: 20px;
	position: relative;

	&.error {
		.select {
			border: 1px solid $dangerField !important;
		}
	}

	&.manageBottlePage {
		label {
			padding-left: 0;
		}
		.select {
			.selectedOption {
				&.white {
					color: $secondary;
				}

				&.red {
					color: $primaryVariant;
				}
			}
		}

		.select {
			background: white;

			.selectedOption {
				background: white;
			}
		}
	}

	label {
		display: flex;
		padding-left: 30px;
		padding-bottom: 10px;
		color: $primary;

		&.white {
			color: $secondary;
		}

		&.red {
			color: $primaryVariant;
		}
	}

	.hiddenInput {
		position: absolute;
		visibility: hidden;
		z-index: -1;
	}

	.selectedOption {
		box-sizing: border-box;
		display: inline-block;
		width: 100%;
		border: 0;
		margin: 0;
		background: #f4f2f2;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 100%;
		@include font(Ubuntu, 500, 18px, 0, 135%);
	}

	.select {

		&.active {
			border-radius: 100px 200px 0 0px;

			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

			.options {
				opacity: 1;
				visibility: visible;
			}

			.pointer {
				transform: translateY(-50%) rotate(180deg);
			}
		}

		::placeholder {
			font-size: 12px;
			font-weight: 600;
			font-style: italic;
			color: lightgrey;
		}

		.pointer {
			position: absolute;
			right: 45px;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
		}

		.options {
			opacity: 0;
			visibility: hidden;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			top: 100%;
			position: absolute;
			background: white;
			left: 0;
			right: 0;
			padding: 5px 0 15px;
			border-radius: 0 0 0px 30px;
			z-index: 9999;
			max-height: 300px;
			overflow: auto;

			.option {
				@include font(Ubuntu, 500, 18px, 0, 170%);
				padding: 5px 30px;
			}
		}

		@media (min-width: 1000px) {
			min-height: 49px;
		}
	}

	.errorMessage {
		color: $dangerField;
		font-size: 12px;
		padding: 5px 30px;
	}
}