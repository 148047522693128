@import "src/styles/variables.scss";
@import "src/styles/mixins/font-face.scss";

.textinputSection {
	padding-bottom: 20px;

	&.noBottomPadding {
		padding-bottom: 0;
	}

	&.isStatic {
		margin-bottom: 20px;
		padding: 15px 0;
		text-align: left;

		h6 {
			padding-left: 30px;
		}
	}

	&.centered {
		.inputWrapper {
			& > * {
				text-align: center;
			}
		}
	}

	&.white {
		&.isStatic {
			color: $secondary;
			border-top: 1px solid $secondary;
			border-bottom: 1px solid $secondary;
		}

		label {
			color: $secondary;
		}

		input {
			color: $secondary;
		}

		.inputWrapper {
			h6 {
				color: $secondary;
			}
		}
	}

	&.red {
		&.isStatic {
			color: $primary;
			border-top: 1px solid $primary;
			border-bottom: 1px solid $primary;
		}

		label {
			color: $primary;
		}

		input {
			color: $primary;
		}

		.inputWrapper {
			color: $primary;
		}
	}

	&.listedFormValue {
		padding-bottom: 25px;
	}

	&.error {
		.inputWrapper {
			border: 1px solid $dangerField !important;
			//box-shadow: 3px 6px 12px var(--danger);
		}
	}

	.errorMessage {
		color: $dangerField;
		font-size: 12px;
		padding: 5px 30px;
	}

	.lengthDescription {
		color: #595858;
		font-size: 12px;
		padding: 5px 30px;
	}

	.maxLengthError {
		color: $dangerField;
	}

    .example {
        font-size: 12px;
		padding: 5px 30px;
        font-weight: 600;
    }

	.grayInput {
		filter: grayscale(1);
		opacity: 0.6;
	}

	.textinputLabel {
		font-size: 11pt;
		font-weight: bold;
		margin: initial;
		margin-top: 20px;
		margin-bottom: 2px;
	}

	label {
		display: flex;
		padding-left: 30px;
		padding-bottom: 10px;
		color: $primary;
	}

	textarea {
		height: 300px;
	}

	input, textarea {
		@include font(Ubuntu, 500, 18px, 0, 135%);
		border: 0;
		background: transparent;

		&::placeholder {
			font-size: 12px;
			font-weight: 600;
			font-style: italic;
			color: lightgrey;
		}

		&:focus {
			outline: none;
		}

		&.invalid {
			color: $dangerField;
			border-bottom: 2px solid $dangerField;
		}

		&.outlined {
			display: inline-block;
			width: 100% !important;
			height: 41px;
			border-radius: 10px;
			font-size: 16px;
			padding: 12px 14px;
			margin: 0px;

			::placeholder {
				font-size: 12px;
				font-weight: 600;
				font-style: italic;
				color: lightgrey;
			}
		}

		&.rounded {
			font-size: 16px;
			border: var(--cardBorderThin);
			padding: 7.5px 45px 7.5px 45px;
			border-radius: 30px;
			background: #fafafa;
			position: relative;
			box-shadow: var(--weakShadow);
		}
	}

	.inputWrapper {
		box-sizing: border-box;
		width: 100%;
		font-size: 16px;
		margin: 0;
		border-radius: 25px 50px 0 25px;
		background: $surface;
		padding: 12px 30px 11px;
		line-height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		position: relative;

		.clearFileButton {
			position: absolute;
			top: 0;
			right: 0;
			width: 20px;
			height: 20px;
			top: 20px;
			right: 20px;
			cursor: pointer;
		}

		.imagePreview {
			height: 300px;

			img {
				padding: 0;
				height: 100%;
				width: 100%;
				object-fit: contain;
			}
		}

		&.inputWrapperWithFile {
			flex-direction: column;
			justify-content: center;

			input {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				opacity: 0;
				cursor: pointer;
			}
		}

		& * {
			width: 100%;
		}

		&.withHint {
			input {
				text-align: right;
			}

			h6 {
				display: inline-flex;
				align-items: center;
			}
			& * {
				width: 50%;
			}
		}

		@media (min-width: 1000px) {
			width: 100%;
		}
	}

	.textinputWrapper {
		.textinputIcon {
			position: absolute;
			top: 7px;
			left: 15px;
		}
	}

	&.textInputFullWidth {
		display: block;
		width: 100%;
		max-width: none !important;
	}

	&.withClearButton {
		max-width: auto;
		display: inline-flex;
	}

	.textinputClearIcon {
		margin-left: 10px;
	}

	.textinputIcon {
		position: absolute;
		padding-top: 8px;
		margin-left: -30px;
	}

	input[type="time"],
	input[type="file"] {
		width: initial;
	}

	input[type="number"] {
		border-bottom: none;
	}

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		font-size: 1em;
		font-style: italic;
		font-weight: 600;
		color: lightgrey;
	}

	&.largeDesktop,
	&.manageBottlePage {
		label {
			padding-left: 0;
		}
		.inputWrapper {
			background-color: $white;
		}
	}

	textarea {
		resize: none;
		height: 300px;

		&.enableTextAreResize {
			resize: initial;
			height: unset;
		}
	}
}

.textInputSmallWrapper {
	max-width: 300px;
	display: inline-block;
}
