.downloadCheck {
	cursor: pointer;
	box-sizing: border-box;
	width: 100%;
	min-width: 305px;
	font-size: 16px;
	margin: 0;
	border-radius: 100px 200px 0 100px;
	background: #f4f2f2;
	line-height: 100%;
	height: 49px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 12px;

	.downloadCheckDescripition {
		display: flex;

		span {
			font-weight: 500;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.0015em;
			display: flex;
			align-items: center;
			padding-left: 30px;
		}
	}

	button {
		width: 80px;
		border-radius: 0 46px 0 0;
		background-color: #e9e4e3;
		object-fit: contain;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
}

@media (max-width: 340px) {
	.downloadCheck {
		min-width: unset;

		.downloadCheckDescripition {
			span {
				padding-left: 13px;
			}
		}
	}
}
