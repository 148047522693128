.Form {
	&.registerGoogleForm {
		width: 100%;
		display: -webkit-box !important;
		display: -webkit-flex !important;
		display: -ms-flexbox;
		display: flex;
		flex-direction: column;

		@media (min-width: 600px) and (max-width: 1149px) {
			align-items: center;
			> div {
				width: 100%;
				max-width: 768px;
				margin: 0 auto;
				box-sizing: border-box;
			}

			> section {
				//border-radius: 200px 100px 100px 0;
				width: 100%;
			}
		}

		@media (min-width: 1150px) {
			height: 100%;
			width: unset;
			margin: 0 auto;
			display: flex;
			//align-items: center;
			justify-content: space-between;
			flex-direction: row;

			> div {
				max-width: 322px;
			}

			> div:nth-child(2) {
				padding: 0px 83px 0;
			}
		}

		@media (max-width: 1150px) {
			> div:nth-child(2) {
				-webkit-box-ordinal-group: 2;
				-webkit-order: 3;
				-ms-flex-order: 3;
				order: 3;
			}

			> section {
				-webkit-box-ordinal-group: 3;
				-webkit-order: 2;
				-ms-flex-order: 2;
				order: 2;
			}
		}
	}
}
