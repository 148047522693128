@import "src/styles/variables.scss";
@import "src/styles/mixins/font-face.scss";

.FormEntryBlock {
	padding: 30px;
	background: $primaryDarken;
	color: $surface;
	text-align: center;
	word-break: break-all;

	&.lighten {
		background: $backgroundLighten;
		color: $primaryDarken;
	}

	&.surface {
		background: $surface;
		color: $primaryDarken;
	}

	img {
		width: 100%;
		object-fit: contain;
	}
	&.transparent {
		background: transparent;
		color: $primaryDarken;
	}

	&.red {
		background-color: $primary;
	}

	&.white {
		background-color: $secondary;
	}

	&.left {
		text-align: left;
	}

	&.content-lg {
		:global {
			.container {
				@include font(Ubuntu, 500, 18px, 0.15px, 135%);

				span {
					@include font(Ubuntu, 500, 18px, 0.15px, 135%);
				}
				& > * {
					@include font(Ubuntu, 500, 18px, 0.15px, 135%);
				}
			}
		}
	}

	:global {
		.container {
			padding: 0;
		}
	}

	&.largeDesktop {
		@media (min-width: 1000px) {
			background: $white !important;
			display: flex;
			width: 80%;
			padding: 38px 0 0;
		}
	}

	&.registerGoogleForm {
		background-color: $primary;
		> div {
			display: flex;
			align-items: center;
			height: 100%;

			> div {
				color: white;
				font-size: 13px;
			}
		}
		@media (min-width: 350px) {
			background-color: unset;
			background-image: url("../../../images/hexagon_big.svg");
			padding: 0 !important;
			width: 300px;
			background-size: cover;
			background-repeat: no-repeat;
			height: 333px;
		}
	}
}
