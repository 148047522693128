@import "src/styles/mixins/font-face.scss";
@import "src/styles/variables.scss";

.FormWrapper {
	padding: 30px 30px 10px;

	&.title-xl {
		.headline {
			h2 {
				@include font(Ubuntu, 500, 40px, 0.25px, 130%);
			}
		}
	}

	&.gray {
		background: #f4f2f2;
	}

	&.wide {
		width: 100%;
		box-sizing: border-box;
	}

	&.largeDesktop {
		@media (min-width: 1000px) {
			//padding: 46px 0 0px;
		}
	}

	&.isFadeBg {
		background: $surface;

		input {
			background: white;
		}
	}

	.headline {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 0 15px;

		@media (max-width: 768px) {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		h2 {
			text-align: left;
			@include font(Ubuntu, 500, 24px, 0, 135%);
		}

		.subTitle {
			text-align: right;
			@include font(Ubuntu, 500, 18px, 0, 135%);
			time {
				@include font(Ubuntu, 500, 18px, 0, 135%);
			}

			@media (max-width: 768px) {
				margin-top: 20px;
			}
		}
		&.red {
			color: $primary;
		}

		&.white {
			color: $secondary;
		}
	}

	&.profilePage {
		padding: 0px;
		.headline {
			padding-left: 32px;
			color: #b3788f;

			@media (max-width: 450px) {
				padding-left: 0;
			}
		}

		@media (max-width: 1200px) {
			padding: 30px 30px 10px;
		}
	}

	//@media (min-width: 1000px) {
	//	padding: 30px 74px 0px;
	//}
	&.registerGoogleForm {
		@media (min-width: 1150px) {
			padding: 0;
		}
	}

	&.auctionPage {
		@media (min-width: 500px) and (max-width: 1000px) {
			max-width: 80%;
			margin: auto;
		}
	}

	&.nonPadding {
		@media (max-width: 1000px) {
			padding: 0 !important;
		}
	}
}
