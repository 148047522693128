@import "src/styles/variables.scss";

.ButtonWrapper {
	position: relative;

	&.loading {
		.loader {
			visibility: visible;
		}
	}

	&.switchButton {
		width: 100%;

		.Button {
			min-width: auto;
			padding: 4px 20px;
		}
	}

	&.profilePage {
		padding-top: 47px;

		button {
			width: 100%;
		}
	}

	&.registerGoogleForm {
		width: 100%;
	}

	.loader {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 200px 100px 100px 0;
		background: rgba(0, 0, 0, 0.7);
		visibility: hidden;
		box-shadow: 0 0 1px rgb(0 0 0 / 70%);

		svg {
			height: 30px;
			width: 30px;
		}
	}

	.Button {
		border-radius: 200px 100px 100px 0;
		padding: 6.5px 30px;
		cursor: pointer;
		width: 100%;
		font-size: 23px;
		min-width: 273px;
		font-weight: 500;

		@media (max-width: 500px) {
			font-size: 20px;
			min-width: 250px;
		}

		&.red {
			background: $primary;
			color: white;
		}

		&.white {
			background: $secondary;
			color: white;
		}

		&.darken {
			background: $primaryDarken;
			color: white;
		}

		&.lighten {
			background-color: $surface;
			color: $primaryDarken;
		}

		&.strictPadding {
			padding: 13px 30px;
		}

		&.disabled {
			cursor: not-allowed !important;

			&.red {
				background: $disabledRed;
			}

			&.white {
				background: $disabledGreen;
			}

			&.darken {
				opacity: 0.4;
			}
		}
	}
}
