@import "src/styles/variables.scss";

.ComingSoonBlock {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background: $backgroundLighten;
	height: calc(100vh);
	position: relative;

	.GrapesBackground {
		@media (orientation: landscape) and (max-height: 620px) {
			height: 80%;
			width: auto;
		}
	}

	.WarningMessage {
		font-family: Ubuntu;
		font-style: normal;
		font-weight: 500;
		font-size: 49px;
		line-height: 63px;
		color: $primaryDarken;
		position: absolute;
		text-align: center;
		width: 90vw;
		max-width: 700px;

		@media (orientation: landscape) and (max-height: 620px) {
			font-size: 30px;
			line-height: 45px;
		}

		@media (orientation: portrait) and (max-width: 400px) {
			font-size: 30px;
			line-height: 45px;
		}

		.ComingSoonTitle {
			font-size: 100px;
			line-height: 63px;
			font-weight: 400;
		}
	}
}
