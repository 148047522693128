@import "src/styles/variables.scss";

.Checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 20px;
	cursor: pointer;

	.checkboxLabel {
		width: fit-content;
		padding-left: 20px;
		box-sizing: border-box;
		cursor: inherit;
	}

	.check {
		appearance: none;
		border: 2px solid #e5e5e5;
		border-radius: 3px;
		height: 25px;
		width: 25px;
		margin: 0;
		position: relative;
		cursor: inherit;

		&.invertFormColors {
			border: 2px solid white;
			background-color: white;
		}

		&.error {
			border: 2px solid $dangerField;
		}

		&:after {
			content: "";
			top: 1px;
			left: 1px;
			right: 1px;
			bottom: 1px;
			position: absolute;
			background-image: url("./check.svg");
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			transform: rotate(-60deg) scale(0.9);
			opacity: 0;
			transition: all 0.2s ease-in-out;
		}

		&:checked {
			&:after {
				opacity: 1;
				transform: rotate(-90deg) scale(1);
			}
		}

		&:disabled {
			background-color: $backgroundLighten;

			@media (hover: hover) {
				cursor: not-allowed !important;
			}
		}
	}
}
