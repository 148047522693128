.FormValueWrapper {
	display: flex;
	flex-direction: column;
	max-width: 768px;
	width: 100%;
	margin: 0 auto;
	padding: 10px 0 30px 0;
	box-sizing: border-box;

	button {
		width: 100%;
	}

	&.centered {
		justify-content: center;
		text-align: center;
	}

	&.blankTop {
		padding-top: 0;
	}

	&.registerGoogleForm {
		padding-top: 48px;
	}

	a {
		display: block;
	}
}
