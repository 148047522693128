@import "src/styles/variables.scss";

.bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.7);
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(0px);
	transition: all 0.3s ease-in-out;
	opacity: 0;
	visibility: hidden;

	&.activeBg {
		backdrop-filter: blur(4px);
		opacity: 1;
		visibility: visible;
	}
}

.PopupContainer {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transform: scale(0.8) translateY(-50px);
	transition: all 0.3s ease-in-out;
	padding: 15px;
	box-sizing: border-box;

	&.activePopupBg {
		opacity: 1;
		visibility: visible;
		transform: none;
	}

	.Popup {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background: $surface;
		border-radius: 21px 21px 67px 0px;
		padding: 61px 44px 42px 39px;
		position: relative;
		max-width: 400px;
		height: 500px;

		@media (max-height: 500px) {
			padding: 15px;
			width: 100%;
			height: 100%;
			border-radius: 0;
			box-sizing: border-box;
		}

		@media (max-width: 500px) {
			height: 100%;
		}

		&.default {
			background: $surface;
		}

		&.redWine {
			background: $primary;
		}

		&.blueWine {
			background: $secondary;
		}

		.close {
			cursor: pointer;
			z-index: 12;
			top: 22px;
			right: 11px;
			position: absolute;

			svg {
				padding: 0;
			}
		}

		.PopupText {
			// margin: 40px 0;

			@media (max-height: 500px) {
				overflow: auto;
			}

			@media (max-height: 500px) {
				margin: 0;
			}
		}

		.messageToSign,
		.mainTitle {
			font-weight: 500;
			text-align: center;
			letter-spacing: 0.0015em;
		}

		.mainTitle {
			font-size: 23px;
		}

		.messageToSign {
			word-break: break-all;
			font-size: 18px;
		}

		.ActionPanel {
			display: flex;
			flex-direction: column;
			align-items: center;

			@media (max-height: 500px) {
				flex-direction: row;
				justify-content: space-around;
			}

			.PopupButtons {
				margin-top: 30px;
				padding: 10px 30px;
				width: 100%;
				border-radius: 200px 100px 100px 0;
				font-weight: 500;
				font-size: 23px;
				line-height: 120%;
				cursor: pointer;
				background: $primary;
				color: white;

				@media (max-height: 500px) {
					box-sizing: border-box;
					padding: 10px 30px;
					max-width: 250px;
				}
			}
		}
	}

	.icon {
		width: 80px;
		height: 80px;
		margin: 0 auto 20px;

		img {
			height: 100%;
			width: 100%;
		}
	}
}
