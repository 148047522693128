@import "src/styles/functions/font-face.scss";
@import "src/styles/mixins/font-face.scss";

@include font-face(
	Ubuntu,
	"/fonts/Ubuntu/Ubuntu-Bold",
	600,
	normal,
	ttf
);
@include font-face(
	Ubuntu,
	"/fonts/Ubuntu/Ubuntu-BoldItalic",
	600,
	italic,
	ttf
);
@include font-face(
	Ubuntu,
	"/fonts/Ubuntu/Ubuntu-Medium",
	500,
	normal,
	ttf
);
@include font-face(
	Ubuntu,
	"/fonts/Ubuntu/Ubuntu-MediumItalic",
	500,
	italic,
	ttf
);
@include font-face(
	Ubuntu,
	"/fonts/Ubuntu/Ubuntu-Regular",
	400,
	normal,
	ttf
);
@include font-face(
	Ubuntu,
	"/fonts/Ubuntu/Ubuntu-Light",
	400,
	normal,
	ttf
);
@include font-face(
	Ubuntu,
	"/fonts/Ubuntu/Ubuntu-LightItalic",
	400,
	italic,
	ttf
);

@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-Black",
	900,
	normal,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-ExtraBold",
	800,
	normal,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-Bold",
	700,
	normal,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-SemiBold",
	600,
	normal,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-Medium",
	500,
	normal,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-Regular",
	400,
	normal,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-Light",
	300,
	normal,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-ExtraLight",
	200,
	normal,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-Thin",
	100,
	normal,
	ttf
);

@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-BlackItalic",
	900,
	italic,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-ExtraBoldItalic",
	800,
	italic,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-BoldItalic",
	700,
	italic,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-SemiBoldItalic",
	600,
	italic,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-MediumItalic",
	500,
	italic,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-LightItalic",
	300,
	italic,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-ExtraLightItalic",
	200,
	italic,
	ttf
);
@include font-face(
	Poppins,
	"/fonts/Poppins/Poppins-ThinItalic",
	100,
	italic,
	ttf
);
