@import "src/styles/variables.scss";

.GlobalPageLoader {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 4;
	background: $primaryDarken;
	display: flex;

	.LoaderWrapper {
		margin: auto;
	}

	.LoaderOptionalText {
		text-align: center;
		margin-top: 15px;
		color: $backgroundLighten;
	}
}

[class^="Loader_Loader"] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // margin-bottom: 20px;
}