.logo {
	display: flex;
	justify-content: center;
	padding: 50px 0 80px 0;

	img {
		height: 100px;
	}
}

.description {
	img {
		display: none;
	}
}

@media (min-width: 1000px) {
	.logo {
		display: none;
	}
	.description {
		height: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;

		> div {
			width: 30%;
		}

		img {
			display: flex;
		}
	}
}
