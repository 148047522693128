@import "src/styles/variables.scss";
@import "src/styles/mixins/font-face.scss";

.Header {
	box-shadow: $strongShadow;
	height: 70px;
	width: 100%;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	align-items: center;
	z-index: 4;
	background-color: $surface;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 2;
		background: $surface;
	}

	.logo {
		width: 180px;
		display: flex;
		align-items: center;
		z-index: 3;

		img {
			width: 100%;
			object-fit: contain;
		}
	}
	.button {
		z-index: 3;
		position: absolute;
		right: 20px;

		div {
			padding-bottom: 7px !important;
		}
	}

	@media (min-width: 1000px) {
		height: 98px;

		.logo {
			padding-left: 6px;
			width: 285px;
		}

		.button {
			right: 60px;

			div {
				padding-bottom: 7px !important;

				span {
					height: 4px !important;
				}
			}
		}
	}
}

.nav {
	position: absolute;
	top: 70px;
	left: 0;
	right: 0;
	background: $primaryDarken;
	padding: 27px 0 37px;
	border-radius: 0px;
	transform: translateY(-100%);
	z-index: -1;
	transition: all 0.3s ease-in-out;
	visibility: hidden;

	@media (min-width: 1000px) {
		top: 98px;
	}

	&.navExpanded {
		border-radius: 0 0 135px 0;
		transform: none;
		box-shadow: $lightShadow;
		visibility: visible;
	}

	.ul {
		.li {
			text-align: center;
			padding: 13px 0;
			display: flex;
			justify-content: center;

			&.logoutOption {
				.loader {
					display: flex;
					margin-top: 25px;
					margin-left: 5px;

					[class^="Loader_Loader"] {
						width: 15px;
						height: auto;
						position: absolute;
						margin-right: -50px;
						margin-top: -21px;

						& > svg {
							width: inherit;
							height: auto;
						}
					}
				}
			}

			a {
				@include font(Ubuntu, 500, 24px, 0, 135%);
				color: $surface;

				@media (max-width: 500px) {
					@include font(Ubuntu, 500, 20px, 0, 135%);
				}
			}

            &.admin {
                a {
                    color: $primary;
                }
            }

			.logoutButton {
				@include font(Ubuntu, 500, 24px, 0, 135%);
				color: $surface;
				cursor: pointer;
				width: fit-content;

				@media (max-width: 500px) {
					@include font(Ubuntu, 500, 20px, 0, 135%);
				}
			}
		}

		@media (max-height: 825px) {
			overflow: scroll;
			height: 650px;
		}
	}
}
