@import "src/styles/variables.scss";

.InfoBox {
	background-color: $surface;
	padding: 15px;
	border: 1px solid black;
	border-radius: 20px 60px 0 20px;
	margin: 0 auto 20px;
	width: 100%;
	max-width: 500px;
	box-sizing: border-box;

	p {
		word-break: break-word;
		text-align: center;

		@media (max-width: 768px) {
			font-size: 14px;
			line-height: initial;
		}
	}

	.title {
		margin: 15px 0 10px;
		text-align: center;

		@media (max-width: 768px) {
			font-size: 14px;
			line-height: initial;
		}
	}

	.icon {
		width: 50px;
		height: 50px;
		margin: 0 auto;

		@media (max-width: 768px) {
			width: 50px;
			height: 50px;
		}

		img {
			height: 100%;
			width: 100%;
		}
	}

	&.info {
		border: 2px solid #6695dc;
	}

	&.warning {
		border: 2px solid #fea945;
	}

	&.success {
		border: 2px solid #6cb77f;
	}

	&.danger {
		border: 2px solid #d5665a;
	}

	&.profilePage {
		padding: 30px 15px 20px;
		width: 70%;
		.icon {
			img {
				max-height: unset;
			}
		}

		.title {
			font-size: 16px;
			margin: 30px 0 10px;
		}
		@media (min-width: 768px) {
			width: 60%;
		}
	}

	&.wineCellar {
		@media (min-width: 768px) {
			width: 70%;
			margin-top: 20px;
		}
	}
}
