.AgeRestriction {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 600px;
	padding: 10px;

	.GrapesBackground {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
	}

	.WarningMessage {
		font-size: 20px;
		font-weight: normal;
		text-align: center;
	}

	.buttonsWrapper {
		display: flex;
		justify-content: center;
		flex-direction: row;	

		& > * {
			padding: 20px 10px;
		}

		@media (max-width: 700px) {
			flex-direction: column;	
		}
	}
}
