$animationDuration: 4s;

$animationDelay1: 0.2s;
$animationDelay2: 0.4s;
$animationDelay3: 0.6s;
$animationDelay4: 0.8s;
$animationDelay5: 1s;

@keyframes logoLoader {
	0% {
		opacity: 0;
	}

	40% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.Loader {
	&.info-box {
		height: 50px;
		width: 50px;
		svg {
			height: 100%;
			width: 100%;
		}

		.loadAnimation1,
		.loadAnimation2,
		.loadAnimation3,
		.loadAnimation4,
		.loadAnimation5,
		.loadAnimation6,
		.loadAnimation7,
		.loadAnimation8,
		.loadAnimation9 {
			fill: #6695dc;
		}
	}

	&.confirmation-popup,
	&.version {
		svg {
			height: 80px;
			width: 80px;
		}

		.loadAnimation1,
		.loadAnimation2,
		.loadAnimation3,
		.loadAnimation4,
		.loadAnimation5,
		.loadAnimation6,
		.loadAnimation7,
		.loadAnimation8,
		.loadAnimation9 {
			fill: #b3788f;
		}
	}

	svg {
		height: 120px;
		width: 120px;
	}

	.loadAnimation1,
	.loadAnimation2,
	.loadAnimation3,
	.loadAnimation4,
	.loadAnimation5,
	.loadAnimation6,
	.loadAnimation7,
	.loadAnimation8,
	.loadAnimation9 {
		opacity: 0;
		animation-name: logoLoader;
		animation-iteration-count: infinite;
	}

	.loadAnimation1 {
		animation-delay: $animationDelay1;
		animation-duration: $animationDuration;
	}
	.loadAnimation2 {
		animation-delay: $animationDelay3;
		animation-duration: $animationDuration;
	}
	.loadAnimation3 {
		animation-delay: $animationDelay2;
		animation-duration: $animationDuration;
	}
	.loadAnimation4 {
		animation-delay: $animationDelay3;
		animation-duration: $animationDuration;
	}
	.loadAnimation5 {
		animation-delay: $animationDelay4;
		animation-duration: $animationDuration;
	}
	.loadAnimation6 {
		animation-delay: $animationDelay4;
		animation-duration: $animationDuration;
	}
	.loadAnimation7 {
		animation-delay: $animationDelay5;
		animation-duration: $animationDuration;
	}
	.loadAnimation8 {
		animation-delay: $animationDelay1;
		animation-duration: $animationDuration;
	}
	.loadAnimation9 {
		animation-delay: $animationDelay1;
		animation-duration: $animationDuration;
	}
}
