.FullPageContentWrapper {
	min-height: calc(100vh - 98px);
	display: flex;
	flex-direction: column;
	height: 100%;

	@media (max-width: 1000px) {
		min-height: calc(100vh - 70px);
	}
}
