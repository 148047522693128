@import "src/styles/variables.scss";

.languageSelection {
	margin: 15px 0 0 0;
	text-align: center;
	font-size: 18px;
	color: $surface;

	button {
		font-size: 18px;
		margin: 0 7.5px;
		color: $primaryDarken;
		text-align: center;
		font-weight: 500;
		cursor: pointer;
		position: relative;
		width: 34px !important;
		height: 39px;

		&.selected {
			position: relative;
			width: 34px;
			height: 39px;
			background-image: url("../../images/hexagon.svg");
		}
	}

	&.homePage {
		position: absolute;
		top: 76px;
		right: 30px;

		button {
			color: $primaryDarken;
		}

		@media (min-width: 1000px) {
			top: 104px;
		}
	}

	&.manageBottlePage {
		display: none;
	}

	&.footer {
		button {
			color: $surface;

			&.selected {
				color: $primaryDarken;
			}
		}
	}
}
