@import "src/styles/variables.scss";
@import "src/styles/mixins/font-face.scss";

.StaticFormValue {
	padding-bottom: 20px;

	label {
		display: flex;
		color: $primary;
	}

	&.secondary {
		padding-bottom: 50px;

		label {
			@include font(Ubuntu, 500, 14px, 0, 135%);
			color: $primaryDarken;
		}
		p {
			@include font(Ubuntu, 400, 14px, 0, 135%);
		}
	}

	@media (min-width: 1000px) {
		label {
			font-size: 18px !important;
			font-weight: 500 !important;
			color: $primary !important;
		}

		p {
			font-size: 15px !important;
			font-weight: 500 !important;
		}
	}
}
