.CheckboxForCellar {
    z-index: 2;
    position: absolute;
    height: 20px;
    margin-left: 50px;

    .CheckboxForCellarImage {
        appearance: none;
        border: 2px solid #e5e5e5;
		border: none;
		height: 20px;
		width: 20px;
		margin: 0;
		position: relative;
		cursor: inherit;

        svg {
            width: inherit;
            height: inherit;
        }
    }
}