@import "src/styles/variables.scss";
@import "src/styles/mixins/font-face.scss";

.web3ProviderSelection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0;

  @media (max-width: 450px) {
    margin: 0;
  }

  .web3ProviderInfo {
    display: flex;
    align-items: center;
  }

  .web3ProviderItem {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #fff;

    .web3ProviderImage {
      width: auto;
      height: 35px;
    }
  }

  .address {
    @include font(Ubuntu, 500, 24px, 0, 200%);
    color: $surface;
    margin-left: 5px;

    @media (max-width: 450px) {
      @include font(Ubuntu, 500, 18px, 0, initial);
    }
    
    .copyIcon {
      display: none;
    }
  }

  &.profilePage {
    margin: 30px 0 0;
    width: unset;
    align-items: center;
    flex-direction: column;

    @media (max-width: 450px) {
      align-items: flex-start;
    }

    .web3ProviderItem {
      border: 1px solid $primaryVariant;
      box-shadow: $weekShadow;

      img {
        width: 50px;
      }
    }

    .address {
      color: $primaryDarken;
      word-break: break-all;
      line-height: 100%;
      display: flex;

      .copyIcon {
        display: flex;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;

        &.isCopiedToClipboard {
          border: 2px solid;
          border-color: $secondary;
          border-radius: 50%;
          
          width: 18px;
          height: 18px;
        }
      }

      .copyText {
        display: flex;
        align-self: center;
        word-wrap: normal;
        word-break: keep-all;  
        line-break: auto;  
      }
    }

    @media (min-width: 1000px) {
      justify-content: center;
      align-items: center;
    }
  }
}
