.confirmationLinkWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .emailNotConfirmedLabel {
        padding: 10px 30px 20px;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #b3788f;
    }

    .emailConfirmationButton {
        max-width: 400px;
    }
}