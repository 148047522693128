@import "src/styles/variables.scss";

.Label {
	&.white {
		color: $secondary;
	}

	&.red {
		color: $primaryVariant;
	}

	.required {
		line-height: 1;
		padding-left: 5px;
		color: red;
	}
}
