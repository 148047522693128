$primary: #b3788f;
$primaryVariant: $primary;
//$primaryVariant: #a3536a;

$textOnPrimaryBg: #b3788f;

$secondary: #599568;
$secondaryVariant: #6cb77f;
$backgroundLighten: #e9e4e3;
$surface: #f4f2f2;
$white: white;
$error: #e93f68;
$primaryDarken: #2d2e2b;

$strongShadow: 0 4px 4px rgba(0, 0, 0, 0.1);
$weekShadow: 0 0 4px rgba(0, 0, 0, 0.1);
$lightShadow: 1px 2px 8px 0px rgb(233, 228, 227, 0.8);

$headerSize: 70px;

$dangerField: #e74c3c;
$warningField: #e67e22;
$disabledRed: rgba(179, 120, 143, 0.5);
$disabledGreen: rgb(89, 149, 104, 0.5);
