@import "src/styles/mixins/font-face.scss";
@import "src/styles/variables.scss";

* {
	line-height: 1.7;
	@include font(Ubuntu, 400, 12px, 0.25px, 1.6);
}

strong {
	font-weight: bold;
}

iframe {
    display: none !important;
}

html,
body,
#root,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	word-break: break-word;

	strong {
		font-weight: bold;
	}
}

#root {
	padding-top: 70px;

	@media (min-width: 1000px) {
		padding-top: 0;
	}
}

//body {
//	background: $primaryDarken;
//}

h1 {
	@include font(Ubuntu, 500, 98px, -1.5px, 122%);
}

h2 {
	@include font(Ubuntu, 500, 60px, -0.5px, 140%);

	p {
		@include font(Ubuntu, 500, 60px, -0.5px, 140%);
	}
}

h3 {
	@include font(Ubuntu, 500, 49px, 0, 140%);

	b, strong {
		@include font(Ubuntu, 500, 49px, 0, 140%);
		color: $textOnPrimaryBg;
		@media (max-width: 375px) {
			@include font(Ubuntu, 500, 40px, 0.25px, 130%);
		}
	}
	@media (max-width: 375px) {
		@include font(Ubuntu, 500, 40px, 0.25px, 130%);
	}
	p {
		@include font(Ubuntu, 500, 49px, 0, 140%);
	}
}

h4, h4 * {
	@include font(Ubuntu, 500, 40px, 0.25px, 130%);
}

h5, h5 * {
	@include font(Ubuntu, 500, 24px, 0, 135%);
}

h6, h6 * {
	@include font(Ubuntu, 500, 18px, 0.15px, 135%);
}

.subtitle_primary {
	@include font(Poppins, 400, 18px, 0.15px, 134%);
}

.subtitle_secondary {
	@include font(Poppins, 500, 16px, 0.1px, 130%);
}

.body_primary {
	@include font(Poppins, 400, 15px, 0.5px, 150%);

	& * {
		font-size: inherit;
		font-weight: inherit;
	}
}

strong, em {
	font-size: inherit;
}

em {
	font-weight: inherit;
}

.body_secondary {
	@include font(Poppins, 400, 13px, 0.25px, 160.3%);
}

.button_primary {
	@include font(Poppins, 500, 15px, 1.25px, 120%);
}

.button_secondary {
	@include font(Poppins, 400, 13px, 1.25px, 145%);
}

.overline {
	@include font(Poppins, 400, 10px, 1.5px, 145%);
}

.caption {
	@include font(Poppins, 400, 9px, 1.5px, 130%);
}

html,
body,
#root {
	height: 100%;
}

#root {
	display: flex;

	& > * {
		width: 100%;
	}
}

button {
	padding: 0;
	margin: 0;
	border: 0;
	background: transparent;
}

ul,
li {
	padding: 0;
	margin: 0;
	list-style: none;
}

p {
	margin: 0;
}

a {
	display: inline-flex;
	text-decoration: none;
	cursor: pointer;
}

.hiddenGrape {
	position: absolute;
	bottom: calc(100% - 20px);
	left: 50%;
	z-index: 2;
	transform: translateX(-50%);
	height: 100px;
	width: 40px;
	background: white;

	img {
		height: 100%;
		width: 100%;
		object-fit: contain;
		object-position: bottom;
	}
}

.react-tel-input .selected-flag {
	padding: 0 !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
	background: transparent !important;
}
.react-tel-input .selected-flag:hover {
	background: transparent !important;
}
.react-tel-input .country-list {
	margin-left: -17px !important;
	border-radius: 0 0 60px 3px !important;
}
.react-tel-input .country-list::-webkit-scrollbar {
	display: none;
}
